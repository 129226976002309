type Args = Record<string, string | number>;

/**
 * Injects string with dynamic values
 * @example
 * input: (Email: ${email}, { email: flow@ensemble.com })
 * output: Email: flow@ensemble.com
 *
 * @param s a string
 * @param args an object which keys will be matched to the string - and be replace with its value
 * @returns the injected string
 */
function f(s: string, args: Args) {
  let result = s;
  for (const [key, value] of Object.entries(args)) {
    result = result.replace(new RegExp(`\\\${${key}}`, 'g'), String(value));
  }

  return result;
}

export const strings = {
  common: {
    flow: 'FLOW',
    email: 'Email',
    phoneNumber: 'Phone Number',
    enabled: 'Enabled',
    disabled: 'Disabled',
    notAvailable: 'N/A',
    expired: 'Expired',
    edit: 'Edit',
    saveChanges: 'Save Changes',
    discardChanges: 'Discard Changes',
    delete: 'Delete',
    remove: 'Remove',
    cancel: 'Cancel',
    back: 'Back',
    add: 'Add',
    emptyPlaceholder: '-',
    seeAll: 'See All',
    name: 'Name',
    close: 'Close',
    proceed: 'Proceed',
    noResults: 'No matching results.',
    choose: 'Choose',
    select: 'Select',
    preview: 'Preview',
    yes: 'Yes',
    no: 'No',
    required: 'Required',
    notRequired: 'Not required',
    loading: 'Loading...',
    goBack: 'Go Back',
    options: 'Options',
    publish: 'Publish',
    publishExplicit: 'Publish Staging to Production',
    revert: 'Revert Staging to Production',
    requiredSymbol: '*',
    information: 'Information',
    viewingAs: 'Viewing As',
    changeApp: 'Change App',
    viewProfile: 'View Profile',
    logOut: 'Log Out',
    label: 'Label',
    icon: 'Icon',
    authentication: 'Authentication',
    subscription: 'Subscription',
    subscriptions: 'Subscriptions',
    version: 'Version',
    localization: 'Localization',
    locales: 'Locales',
    sku: 'SKU',
    status: 'Status',
    portrait: 'Portrait',
    landscape: 'Landscape',
    square: 'Square',
    action: 'Action',
    additionalFields: 'Additional Fields',
    layout: 'Layout',
    warning: 'Warning',
    success: 'Success',
    reorder: 'Re-order',
    versionPlaceholder: '1.0.0',
    registered: 'Registered',
    notRegistered: 'Not Registered',
    activated: 'Activated',
    notActivated: 'Not Activated',

    title: 'Title',
    description: 'Description',
    type: 'Type',
    url: 'URL',
    password: 'Password',
    searchFilter: 'Search or Filter',
    lastEditedBy: 'Last Edited By',
    lastPublished: 'Last Published',
    lastpublished: 'Last published',

    emptyColor: '#000000',
    browseFile: 'Browse file',
    toUpload: 'to upload',
    or: 'or',
    selectFromLibrary: 'Select from Library',

    nCharactersMax: (n: Args) => f('${n} characters max', n),
    localeDefault: (locale: Args) => f('${locale} (Default)', locale),

    confirmPublishMessage:
      'Are you sure you want to publish from Staging Environment to Production Environment? This action cannot be undone.',
    confirmRevertStagingMessage:
      'Are you sure you want to revert Staging Environment to match current Production Environment? This action cannot be undone.',
    confirmGenerateOnEnvironmentMessage: (environment: Args) =>
      f(
        'Are you sure you want to publish to the ${environment} Environment? This action cannot be undone.',
        environment,
      ),

    subscribed: 'Subscribed',
    authenticated: 'Authenticated',
    show: 'Show',
    dontShow: "Don't show",
    cancelDelete: 'Cancel Delete',
  },
  authentication: {
    // generic
    requestNewLink: 'Request New Link',
    incompleteEmail: 'Incomplete email.',
    incompletePassword: 'Incomplete password.',
    sendInstructions: 'Send Instructions',
    checkYourInbox: 'Check your inbox',

    // login
    signIn: 'Sign In',
    logIn: 'Log in',
    logInInstructions: 'Enter your credentials to log in.',
    forgotPassword: 'Forgot Password?',
    incompleteEmailAndPassword: 'Incomplete email and password.',
    invalidCredentials: 'Invalid email or password.',
    accountLocked: 'Your account is temporarily locked for up to 15 minutes.',

    // registration
    register: 'Register',
    registerInstructions: 'Enter your information to get started.',
    registrationLinkExpired: 'Registration link expired',
    registrationLinkInvalid: 'This link is no longer valid.',
    registrationLinkSent:
      'A new registration link has been sent to your email address.',
    requestNewRegistrationLinkInstructions: `Enter your email address and we'll send you a new registration link.`,

    // forgot password
    forgotPasswordInstructionsResetLink:
      'Enter your email address and well send you a new password reset link.',
    forgotPasswordInstructionsResetPassword: `Enter the email address associated with your account and we'll send
    an email with instructions to reset your password.`,
    forgotPasswordLinkSent:
      'We have sent password recovery instructions to your email address.',
    passwordResetLinkSent:
      'A new password reset link has been sent to your email address.',

    // create new password
    resetPassword: 'Reset Password',
    createNewPassword: 'Create new password',
    passwordResetLinkExpired: 'Password reset link expired',
    linkNoLongerValid: 'This link is no longer valid.',
    newPassword: 'New Password',
    confirmPassword: 'Confirm Password',
    newPasswordRequirements: {
      length: '8 characters minimum',
      upperCaseChar: 'At least one upper case character',
      lowerCaseChar: 'At least one lower case character',
      specialChar: 'At least one special character',
      digit: 'At least one number',
    },
    passwordDoesNotMeetRequirements: 'Incomplete password.',
    passwordsDoNotMatch: 'Password does not match.',

    // MFA
    verificationCode: 'Verification Code',
    enterVerificationCode: 'Enter a verification code',
    twoStepVerification: '2 Step Verification',
    next: 'Next',
    incompleteVerificationCode: 'Incomplete verification code.',
    incorrectVerificationCode: 'Incorrect verification code.',
    verificationCodeSentTo: (phone: Args) =>
      f('A text message with a verification code was sent to ${phone}.', phone),
  },
  consent: {
    welcomeToFlow: 'Welcome to Flow!',
    acknowledgement: 'Software use requires acknowledgement of:',
    agreeToAll: 'I agree to all.',
    privacyPolicy: 'Privacy Policy',
    termsOfUse: 'Terms of Use',
    consentError: 'You must check all the fields.',
    decline: 'Decline',
    ok: 'OK',
    mustConsentMessage: 'Must consent to product terms & conditions',
  },
  apps: {
    app: 'App',
    apps: 'Apps',
    appName: 'App Name',
    ensembleManager: 'Ensemble Manager',
    createApp: 'Create App',
    editApp: 'Edit App',
    deleteApp: 'Delete App',
    selectAnApp: 'Select an App',
    selectAppDescription: 'Select an app to get started',
    allApps: 'All Apps',
  },
  accounts: {
    account: 'Account',
    accounts: 'Accounts',
    pageSubtitle: 'Select an account to view apps',
    allAccounts: 'All Accounts',
    accountName: 'Account Name',
    accountManagerName: 'Account Manager Name',
    createAccount: 'Create Account',
    editAccount: 'Edit Account',
    awaitRemovalReq: 'Awaiting Removal Request',
    ensManager: 'Ensemble Manager',
    accountContact: 'Account Contact',
    primaryAccountContact: 'Primary Account Contact',
  },
  feeds: {
    feed: 'Feed',
    feeds: 'Feeds',
    feedDetail: 'Feed Detail',
    allFeeds: 'All Feeds',
    name: 'Feed Name',
    contentIntegration: 'Content Integration',
    contentDetailIntegration: 'Content Detail Integration',
    detailIntegration: 'Detail Integration',
    multiSlashSingleRow: 'Multi/Single Row',
    multiOrSingleRow: 'Multi or Single Row',
    multiRow: 'Multi Row',
    singleRow: 'Single Row',
    createFeed: 'Create Feed',
    editFeed: 'Edit Feed',
    platformsAndScreens: 'Platforms and Screens',
    usedOnPlatforms: 'Used On Platforms',
    usedOnScreens: 'Used On Screens',
    numScreens: 'No. of Screens',
  },
  screenFeed: {
    screenFeed: 'Screen Feed',
    detail: 'Screen Feed Detail',
    edit: 'Edit Screen Feed',
    layout: 'Layout',
    itemLayout: 'Item Layout',
    action: 'Action',
    rowItemPageSize: 'Row Item Page Size',
    rowPageSize: 'Row Page Size',
    refreshInterval: 'Refresh Interval',
    refreshIntervalWithSeconds: (seconds: Args) =>
      f('${seconds} second(s)', seconds),
    refreshOnScreenResume: 'Refresh On Screen Resume',
    on: 'On',
    off: 'Off',
    title: 'Title',
  },
  tags: {
    tags: 'Tags',
    badges: 'Badges',
    tuneIn: 'Tune In',
    string: 'String',
    stringKey: 'String Key',
    emptyLocalizedTranslationMessage: 'Get started by adding a string',
    deleteString: 'Delete String',

    // Dialog
    deleteKeyMessage:
      'This will delete strings for all locales. Are you sure you want to delete?',

    // aria labels
    ariaDeleteTag: 'delete tag',
    ariaTagDeleted: 'tag deleted',
  },
  platforms: {
    platform: 'Platform',
    platforms: 'Platforms',
    name: 'Platform Name',
    pageSubtitle: 'Select a platform to edit platform environment',
    addPlatform: 'Add Platform',
    getStartedPlatform: 'Get started by adding a platform',
    unableToAddPlatform: 'Unable to Add Platform',
    noMoreAdditionalPlatforms: 'There are no more additional platforms.',
    allPlatforms: 'All Platforms',
    unableToDeleteNavBarItem: 'Unable to Delete Nav Bar Item',
    cannotDeleteLastNavBarMessage:
      'This Nav Bar Item cannot be deleted. There needs to be at least 1 Nav Bar Item for Home Screen and 1 Nav Bar Item for Account Screen existing.',
    addIntegration: 'Add Integration',
    editIntegration: 'Edit Integration',
    integrationType: 'Integration Type',
    integration: 'Integration',
    integrations: 'Integrations',
    allIntegrations: 'All Integrations',
    configurableValues: 'Configurable Values',
    logLevel: 'Log Level',
    baseURL: 'Base URL',
    createNavBarItem: 'Create Nav Bar Item',
    editNavBarItem: 'Edit Nav Bar Item',
    unableToCreateNavBarItem: 'Unable to Create Nav Bar Item',
    navBarAuthenticationDescription:
      'Require authentication to appear in the nav bar',
    navBarSubscriptionDescription:
      'Require subscription to appear in the nav bar',
    noScreenOnPlatformForType:
      'No screen is enabled on this platform for the selected type.',
    toCreateEditScreenGoTo: 'To create or edit a screen, go to ',
    screenName: 'Screen Name',
    goToScreens: 'Go to Screens',
    allNavBarItems: 'All Nav Bar Items',
    navBar: 'Navigation',
    configuration: 'Configuration',
    platformConfiguration: 'Platform Configuration',
    editConfiguration: 'Edit Configuration',
    minPlatformSdkVersion: 'Min Platform SDK Version',
    minPlatformSdkVersionTip:
      'Earliest Streams Platform SDK the app can run on.',
    currentAppVersion: 'Current App Version',
    appVersion: 'App Version',
    featuresAndSubscriptions: 'Features and Subscriptions',
    features: 'Features',
    certificationStatus: 'Certification Status',
    featuresTip: 'Supported global features for your app.',
    localesTip: 'Supported regions for your app.',
    defaultLocale: 'Default locale',
    subscription: 'Subscription',
    allSubscriptions: 'All Subscriptions',
    subscriptionsTip: 'Current subscription products offered.',
    createSubscription: 'Create Subscription',
    editSubscription: 'Edit Subscription',
    subscriptionName: 'Subscription Name',

    maxNavBarItemsMessage: (maxNavBarItems: Args) =>
      f(
        'Only a maximum of ${maxNavBarItems} Nav Bar Items are supported',
        maxNavBarItems,
      ),
    unableToCreateMaxNavBarItemsMessage: (maxNavBarItems: Args) =>
      f(
        'This Nav Bar Item cannot be created. There can only be ${maxNavBarItems} Nav Bar Items existing.',
        maxNavBarItems,
      ),
    vodAdUrl: 'VOD Ad Url',
    context: 'Context',
    networkTooltip: '{ "network" : "abc" }',
  },
  screens: {
    screen: 'Screen',
    screens: 'Screens',
    allScreens: 'All Screens',
    createScreen: 'Create Screen',
    screenDetail: 'Screen Detail',
    editScreen: 'Edit Screen',
    name: 'Screen Name',
    platforms: 'Platforms',
    appRefresh: 'App Refresh',
    appRefreshDescription: 'Allow app refresh for screen',
    layoutType: 'Layout Type',
    itemLayout: 'Item Layout',
    screenFeeds: 'Screen Feeds',
    allScreenFeeds: 'All Screen Feeds',
    createScreenFeed: 'Create Screen Feed',
    editScreenFeed: 'Edit Screen Feed',
    unableToCreateScreenFeed: 'Unable to Create Screen Feed',
    mustBeOneScreenFeed: 'There must be at least one Screen Feed.',
    allScreenFeedsUsedMessage:
      'No additional Screen Feeds can be created as all Feeds have been used.',
    detail: 'Detail',
    playback: 'Playback',
    refreshOnScreenResume: 'Refresh on Screen Resume',
    refreshInterval: 'Refresh Interval',
    rowPageSize: 'Row Page Size',
    rowItemPageSize: 'Row Item Page Size',
    gridItemPageSize: 'Grid Item Page Size',
  },
  screenContexts: {
    icon: 'Icon',
    backgroundImage: 'Background Image',
    primaryBackgroundImage: 'Primary Background Image',
    secondaryBackgroundImage: 'Secondary Background Image',
    backgroundImageLayout: 'Background Image Layout',
    imageLayout: 'Image Layout',
    passwordMinChars: 'Password Minimum Characters',
    passwordReqUpper: 'Password Require Uppercase',
    passwordReqLower: 'Password Require Lowercase',
    passwordReqNumber: 'Password Require Number',
    passwordReqNonAlphanumeric: 'Password Require Non-Alphanumeric',
    passwordUpperChars: 'Password Uppercase Characters',
    passwordLowerChars: 'Password Lowercase Characters',
    passwordNumberChars: 'Password Number Characters',
    passwordNonAlphanumericChars: 'Password Non-Alphanumeric Characters',
    progressHeartbeat: 'Progress Heartbeat',
    upNextCountdown: 'Up Next Countdown',
    upNextCue: 'Up Next Cue',
    upNextBackgroundColor: 'Up Next Background Color',
    countdown: 'Countdown',
    inactivityInterval: 'Inactivity Interval',
    seconds: 'second(s)',
    hours: 'hour(s)',
    chars: 'character(s)',
    qrCode: 'Display QR Code',
    pollingInterval: 'Polling Interval',
    alwaysShowOnLaunch: 'Always Show On Launch',
    passwordMinCharsTooltip:
      'Set the value to 0 if no specific minimum requirement is necessary',
    prerollAdFrequency: 'Pre-Roll Ad Frequency',
    autoFullScreenTransition: 'Auto Full Screen Transition',
  },
  screenLocalizationLabels: {
    privacyConsentButton: 'Privacy Consent Button',
    description: 'Description',
    toggle: 'Toggle',
    unavailable: 'Unavailable',
    title: 'Title',
    seasonTitle: 'Season Title',
    episodeDetailOverlayTitle: 'Episode Detail Overlay Title',
    seriesDetailOverlayTitle: 'Series Detail Overlay Title',
    movieDetailOverlayTitle: 'Movie Detail Overlay Title',
    playbackTitle: 'Title (Playback Context)',
    accountTitle: 'Title (Account Screen Context)',
    noResultsTitle: 'No Results Title',
    offlineTitle: 'Offline Title',
    retryTitle: 'Retry Title',
    tryAgainTitle: 'Try Again Title',
    errorTitle: 'Error Title',
    emptyTitle: 'Empty Title',
    message: 'Message',
    messages: 'Messages',
    legalMessage: 'Legal Message',
    upNextMessage: 'Up Next Message',
    playbackMessage: 'Message (Playback Context)',
    accountMessage: 'Message (Account Screen Context)',
    noResultsMessage: 'No Results Message',
    accountExistsErrorMessage: 'Account Exists Error Message',
    offlineMessage: 'Offline Message',
    retryMessage: 'Retry Message',
    tryAgainMessage: 'Try Again Message',
    errorMessage: 'Error Message',
    emptyMessage: 'Empty Message',
    loginButton: 'Login Button',
    logoutButton: 'Logout Button',
    forgotPasswordButton: 'Forgot Password Button',
    registerButton: 'Register Button',
    skipButton: 'Skip Button',
    backButton: 'Back Button',
    termsServiceButton: 'Terms of Service Button',
    termsPurchaseButton: 'Terms of Purchase Button',
    privacyPolicyButton: 'Privacy Policy Button',
    playButton: 'Play Button',
    resumeButton: 'Resume Button',
    restartButton: 'Restart Button',
    trackProgressButton: 'Sign In to Track Progress Button',
    showcaseDetailButton: 'Showcase Detail Button',
    showcasePlayButton: 'Showcase Play Button',
    showcaseResumeButton: 'Showcase Resume Button',
    showcaseRestartButton: 'Showcase Restart Button',
    playbackConfirmButton: 'Confirm Button (Playback Context)',
    accountConfirmButton: 'Confirm Button (Account Screen Context)',
    playbackDismissButton: 'Dismiss Button (Playback Context)',
    accountCancelButton: 'Dismiss Button (Account Screen Context)',
    supportButton: 'Support Button',
    ccpaButton: 'CCPA Button',
    thirdPartySoftwareButton: 'Third Party Software Button',
    deleteAccountButton: 'Delete Account Button',
    subscribeButton: 'Subscribe Button',
    subscriptionProductButton: 'Subscription Product Button',
    confirmButton: 'Confirm Button',
    submitButton: 'Submit Button',
    dismissButton: 'Dismiss Button',
    retryButton: 'Retry Button',
    tryAgainButton: 'Try Again Button',
    errorButton: 'Error Button',
    emptyButton: 'Empty Button',
    email: 'Email',
    hintName: 'Name Input Hint',
    hintEmail: 'Email Input Hint',
    hintPassword: 'Password Input Hint',
    hintSearch: 'Search Input Hint',
    instructions: 'Instructions',
    qrInstruction: 'QR Code Instructions',
    qrInstructionAlt: 'QR Code Instructions (Alt)',
    code: 'Code',
    separator: 'Separator',
    passwordCriteria: 'Password Criteria',
    playbackTimeRemaining: 'Playback Time Remaining',
    episodesTab: 'Episodes Tab',
    extrasTab: 'Extras Tab',
    releaseDate: 'Release Date',
    genre: 'Genre',
    creator: 'Creator (Singular)',
    creators: 'Creator (Plural)',
    rating: 'Rating',
    cast: 'Cast',
    notice: 'Notice',
    tag: 'Tag',
    benefits: 'Benefits',
    price: 'Price',
    detail: 'Detail',
    url: 'URL',
    subscriptionStatus: 'Subscription Status',
    noSubscriptionName: 'No Subscription Name',
    version: 'Version',
    subscriptionProductName: 'Subscription Product Name',
    content: 'Content',
    parentalPinTitle: 'Parental PIN Title',
    settingsParentalPINMessage: 'Parental PIN Message',
    forgotButton: 'Forgot Button',
    cancelButton: 'Cancel Button',
    question: 'Question',
    showcaseFullscreenButton: 'Showcase Full Screen Button',
    embeddedPlayerErrorTitle: 'Embedded Player Error Title',
    embeddedPlayerErrorMessage: 'Embedded Player Error Message',
  },
  screenLocalizationDefaults: {
    embeddedPlayerErrorTitleDefault: 'Playback Error',
    embeddedPlayerErrorMessageDefault:
      'There was an error with with playing this stream. Please try again later.',
    showcaseFullscreenDefault: 'Full Screen',
    appSettings: 'App Settings',
    appSettingsParentalPin: 'Restrict access to content for mature audiences',
    privacyConsent: 'Privacy Consent',
    description: 'Description',
    toggle: 'Toggle',
    title: 'Title',
    unavailable: 'Unavailable',
    moviesShowsMore: 'Movies, TV Shows, And More.',
    streamContent1:
      'Stream Full Seasons of Exclusion Series, Current-Season Episodes,',
    streamContent2: 'Hit Movies, Originals, Kid Shows, and More.',
    signUp: 'Sign Up',
    signIn: 'Sign In',
    signOut: 'Sign Out',
    skip: 'Skip',
    name: 'Name',
    email: 'Email',
    password: 'Password',
    invalidCredentials: 'Invalid email or password. Please try again.',
    forgotPassword: 'Forgot Password',
    register: 'Register',
    signInOrRegister: 'Sign In or Register',
    toSignInOrCreate:
      'To sign in or create a new account visit activate.streams.com',
    scanQrCode: 'Scan the code to sign in or create a new account',
    unableToScanQrCode:
      'If you are unable to scan the code visit activate.streams.com',
    enterCode: 'Enter code:',
    or: 'OR',
    signInWithRemote: 'Sign In With Remote',
    passwordCriteria:
      'Your password must be 8 characters in length and include uppercase, lowercase, numerical and non-alphanumerical characters.',
    errorMessage: 'Something went wrong. Please try again.',
    accountExists: 'Account already exists for this email.',
    termsOfService: 'Terms of Service',
    termsOfPurchase: 'Terms of Purchase',
    privacyPolicy: 'Privacy Policy',
    ccpaPrivacy: 'CCPA Privacy',
    agree:
      'By clicking Register you agree to our Terms of Service and Privacy Policy.',
    forgotPasswordMessage:
      "Enter your email and we'll send you instructions to reset your password.",
    send: 'Send',
    emailSent: 'Email Sent',
    emailSentDetails: 'Email has been sent to {0} with further instructions.',
    returnToSignIn: 'Return to Sign In',
    back: 'Back',
    visitForgotPassword:
      'Please visit streams.com/forgot-password to reset your account password.',
    startWatching: 'Start Watching',
    continue: 'Continue',
    restart: 'Restart',
    allShows: 'All Shows',
    watchlist: 'Watchlist',
    emptyWatchlist: 'Your Watchlist is Empty',
    emptyContinue: 'Your Continue Watching is Empty',
    addTitleHere: "Add title here so they're ready for you to watch later",
    emptyContinueMessage:
      "Start watching your favorite titles, and they'll appear here when you're ready to continue",
    browseContent: 'Browse Content',
    play: 'Play',
    signInToTrackProgress: 'Sign In to Track Progress',
    timeRemaining: '{0} remaining',
    episodes: 'Episodes',
    extras: 'Extras',
    seasonTitle: 'Season {0}',
    episodeDescription: 'Episode Description',
    details: 'Details',
    releaseDate: 'Release Date:',
    genre: 'Genre:',
    creator: 'Creator:',
    creators: 'Creators:',
    rating: 'Rating:',
    starring: 'Starring:',
    search: 'Search',
    searchBy: 'Search by Title, Genre, or Actor',
    noResults: 'No Results',
    tryDifferentSearch: 'Try searching a different title, genre, or actor',
    upNext: 'UP NEXT IN {0}',
    problemOccurred: 'A Problem Occurred',
    havingTrouble:
      "We're having trouble playing this content right now. Please try again later.",
    ok: 'OK',
    subscribersOnly: 'This content is only for subscribed users',
    purchaseSubscription: 'Please purchase a subscription to continue',
    subscribe: 'Subscribe',
    cancel: 'Cancel',
    upgrade: 'Upgrade to Streams+',
    selectSubscribe: 'Please select subscribe to continue',
    choosePlan: 'Choose Your Plan',
    freeTrialIncluded:
      '7 day free trial included, Cancel or change plans anytime',
    freeTrialNewCustomersOnly: '7 day free trial for new customers only',
    streamsPlusAnnual: 'Streams+ Annual',
    mostPopular: 'MOST POPULAR',
    twoWeekFreeTrial: '2 week free trial',
    saveFortyPercent: 'Save over 40% over monthly',
    exclusiveWatching: 'Exclusives and Ad-Free Watching',
    price: '$34.99/year',
    priceDetail: '2 weeks free, then $34.99/year',
    manageSubscription: 'Manage Subscription',
    subscriptionChanges: 'For any subscription changes please visit:',
    subscriptionUrl: 'streams.com/subscription',
    account: 'Account',
    userEmail: 'Email: {0}',
    subscriptionName: 'Subscription: {0}',
    accountChanges: 'For account changes, visit streams.com/account',
    support: 'Support',
    forHelp: 'For help and contact information, please visit:',
    supportUrl: 'support.streams.com',
    thirdPartSoftware: 'Third Party Software',
    deleteAccount: 'Delete Account',
    streams: 'Streams',
    streamsPlus: 'Streams+',
    version: 'v{0}',
    faq: 'FAQ',
    newAppVersion: 'New App Version',
    mustUpgrade:
      'Sorry, you must upgrade to the latest version of Ensemble Streams to continue watching your favourite movies and shows. For more instructions see streams.com',
    confirmExit: 'Are you sure you want to exit?',
    yes: 'Yes',
    no: 'No',
    confirmSignOut: 'Are you sure you want to sign out?',
    willPermanentlyRemoveData:
      'Deleting your account will permanently remove all of your data. This cannot be reversed.',
    areYouSure: 'Are you sure?',
    willInitiateDeletion:
      'By clicking delete account, we will initiate a deletion of your account. Your account will be deleted in the next 5 days.',
    stillWatching: 'Are you still watching {0}?',
    exitTime: 'Exit ({0})',
    noInternet: 'No Internet Connection',
    couldNotConnect:
      'Could not connect to the network. Please check your connection and try again.',
    unableToLoad: 'Unable to load.',
    retry: 'Retry',
    tryAgainLater: 'Try again later.',
    parentalPin: 'Parental PIN',
    parentalPinSet: 'Set Parental PIN',
    parentalPinForgot: 'Forgot Parental PIN',
    parentalPinForgotSet: 'Set Forgot Parental PIN',
    messageEnterNewCode: 'Enter your new 4-digit Parental PIN',
    question: 'What year were you born in?',
    enterParentalPinToStart:
      'Enter your 4-digit Parental PIN to start playback',
    submitButtonContinue: 'Continue',
    submitButton: 'Submit',
    forgotButton: 'Forgot PIN',
    invalidPIN: 'Invalid PIN',
    pinMust4Digit: 'PIN must be 4 digits',
    incorrectAnswer: 'Incorrect answer',
  },
  screenLocalizationTooltips: {
    email: '{0} placeholder replaced with email.',
    minutesRemaining:
      '{0} placeholder replaced with number of minutes remaining in playback.',
    season: '{0} placeholder replaced with season number.',
    countdownSeconds:
      '{0} placeholder replaced with countdown time in seconds.',
    userEmail: '{0} placeholder replaced with signed in user email.',
    subscription: '{0} placeholder replaced with the subscription name.',
    version: '{0} placeholder replaced with the app version.',
    title: '{0} placeholder replaced with movie or episode title.',
  },
  theme: {
    theme: 'Theme',
    customizeTheme: 'Customize Theme',
    logo: 'Logo',
    learnMore: 'Learn More',
    purposeAndUsage: 'Purpose & Usage',
    logoDescription: (appLogoHeight: Args) =>
      f('Logo must have height of ${appLogoHeight}', appLogoHeight),
    premiumIcon: 'Premium Icon',
    premiumIconDescription:
      'Premium Icon must have width of 36px and height of 23px',

    text: 'Text',
    textSecondary: 'Text Secondary',
    button: 'Button',
    buttonBorder: 'Button Border',
    buttonFocused: 'Button Focused',
    buttonFocusedBorder: 'Button Border Focused',
    textInput: 'Text Input',
    textInputBorder: 'Text Input Border',
    textInputFocused: 'Text Input Focused',
    textInputFocusedBorder: 'Text Input Border Focused',
    badge: 'Badge',
    tag: 'Tag',
    progress: 'Progress',
    underline: 'Underline',
    navBarBackground: 'Navigation Bar Background',
    separator: 'Separator',
    background: 'Background',
    overlay: 'Overlay',
    loadingIndicator: 'Loading Indicator',
    cardFocusedBorder: 'Card Focused Border',
    cardPlaceholder: 'Card Loading Background',

    tileFallbackDescription: (pixelConstraints: Args) =>
      f(
        'Image must have width of ${width}px and height of ${height}px',
        pixelConstraints,
      ),
    landscapeFallbackTitle: 'Tile Landscape Fallback Image',
    squareFallbackTitle: 'Tile Square Fallback Image',
    portraitFallbackTitle: 'Tile Portrait Fallback Image',
  },
  users: {
    user: 'User',
    users: 'Users',
    allUsers: 'All Users',
    detail: 'User Detail',
    createUser: 'Create User',
    firstName: 'First Name',
    lastName: 'Last Name',
    edit: 'Edit User',
    delete: 'Delete User',
    isRegistered: 'Registered?',
    tfa: '2FA',
    registrationStatus: 'Registration Status',
    twoFactorAuthentication: 'Two Factor Authentication',
    userOptions: 'User Options',
  },
  roles: {
    appAdmin: 'App Admin',
    sysAdmin: 'System Admin',
  },

  env: {
    env: 'Environment',
    prod: 'Production',
    dev: 'Development',
    stage: 'Staging',
  },

  profile: {
    title: 'Profile',
    changePassword: 'Change Password',
    role: 'Role',
    account: 'Account',
    app: 'App',
    twoFactorAuthentication: 'Two Factor Authentication',

    // Dialog
    changePasswordMessage:
      'Click below and we will send an email with instructions to reset your password.',
    editTwoFactorAuthentication: 'Edit Two Factor Authentication',
    twoFactorAuthenticationDescription:
      'By enabling Two Factor Authentication, you acknowledge that data and text message rates may apply.',
    mobilePhoneNumber: 'Mobile Phone Number',
    mobilePhoneNumberDescription:
      'Mobile phone number is required to enable two factor authentication. Please ensure that you input the correct phone number.',

    // aria labels
    ariaEdit2fa: 'edit two factor authentication',
  },
  dialogs: {
    deleting: 'Deleting',
    removing: 'Removing',
    requestingRemoval: 'Requesting Removal',
    awaitingRemovalRequest: 'Awaiting Removal Request',
    removalRequested: 'Removal Requested',
    alreadyRequestedRemovalMessage:
      'You have already requested removal. Action will be required by another System Admin.',
    requiresTwoSystemAdminApprovals:
      'requires approval from two System Admins.',
    systemAdmin1You: 'System Admin 1 (You)',
    systemAdmin2: 'System Admin 2',
    deleteItemType: (itemType: Args) => f('Delete ${itemType}', itemType),
    unableToDeleteItemType: (itemType: Args) =>
      f('Unable to Delete ${itemType}', itemType),

    confirmDeletionMessage:
      'This action cannot be undone. Are you sure you want to delete?',
    itemUsedOnThesePlatforms: (itemType: Args) =>
      f('This ${itemType} is being used on these platforms:', itemType),
    canOnlyDeleteIfNoPlatforms: (itemType: Args) =>
      f(
        'A ${itemType} can only be deleted if no platforms are using the ${itemType}.',
        itemType,
      ),
    thisItemWillPermanentlyBeDeleted: (itemType: Args) =>
      f('This ${itemType} will be permanently deleted.', itemType),
    areYouSureAction: (action: Args) =>
      f('Are you sure you want to ${action}?', action),
    actionRequiresAnotherSystemAdmins:
      'Action will be required by another System Admin. Are you sure you want to proceed?',

    imagePreview: 'Image Preview',
    mediaLibrary: 'Media Library',
    selectAnImageToUpload: 'Select an image to upload',
  },
  files: {
    noFileSelected: 'No file selected (Max file size: 1MB)',
    byte: 'B',
    kilobyte: 'KB',
    megabyte: 'MB',
    unknown: 'icon.png',

    // aria-labels
    ariaDownloadFile: 'download file',
    ariaDeleteFile: 'delete file',
  },
  errors: {
    error: 'Error',
    oops: 'Oops!',
    generic: 'Something went wrong.',
    pageNotFound: 'Page Not Found',
    pageNotFoundMessage:
      'Sorry, the page you are looking for cannot be accessed.',
    forbidden: 'Forbidden',
    forbiddenMessage: 'You do not have permission to view this page.',
    missingInput: 'One or more fields require your attention.',
    resetUnverifiedEmail: 'Failed to send instructions: email is unverified.', // tentative message for a reset password error
    login: 'Incomplete email or password',
    email: 'Incomplete email.',
    forgotPassword: 'Incomplete email address.',
    passwordsDoNotMatch: 'Password does not match.',
    passwordDoesNotMeetRequirements: 'Password does not meet requirements.',
    verification: 'Incorrect verification code.',
    duplicateKeys: 'Failed to add key: already exists.', // tentative message for adding duplicate translation key
    onlyAlphanumeric:
      'Failed to add key: may only contain alphanumeric characters.', // tentative message for adding translation key with non-alphanumeric characters
    failedToSave: 'A problem occurred while saving, please try again later.', // tentative message if save fails
    unexpectedError: 'Unexpected Error',
    unexpectedErrorMessage:
      'Something went wrong. We are working on fixing the problem.',
    imageNotFoundIcon: 'Image not found icon',
    unableToLoadImage: 'Unable to load image',
    tooManyImages: 'Too many files uploaded.',
    fileSizeExceeded: (size: Args) =>
      f('File size should not exceed ${size}.', size),
  },
  success: {
    saved: 'Your changes have been saved.',
  },
} as const;
